<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="scaffold">
      <div class="vx-row">
        <div class="vx-col w-full mb-10 ">
            <services-list />
        </div>
      </div>
    </div>
</template>
  
<script>
  
import ServicesList from '@/views/components/Services/ServicesList.vue'
export default {
    data () {
      return {}
    },
    components: {
      ServicesList
    },
    computed: {},
    created () {},
    methods: {},
    watch: {}
  }
</script>
  
<style lang="scss">
  /*! rtl:begin:ignore */
#scaffold {
    .greet-user{
        position: relative;

        .decore-left{
        position: absolute;
        left:0;
        top: 0;
        }
        .decore-right{
        position: absolute;
        right:0;
        top: 0;
        }
    }

    @media(max-width: 576px) {
        .decore-left, .decore-right{
        width: 140px;
        }
    }
}
  
</style>
  